import React, { Fragment } from 'react';
import styled from 'styled-components';

import SEO from '../components/SEO';
import { colors } from '../consts/style';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: ${colors.blue};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
`;

const NotFoundPage = () => (
  <Fragment>
    <SEO title="404: Not found" />
    <Wrapper>
      <h1>404</h1>
      <p>Page not found</p>
    </Wrapper>
  </Fragment>
);

export default NotFoundPage;
